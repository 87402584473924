.showreel {
  @apply relative h-0 pb-[67%] md:pb-[58%] xl:pb-[36.46%] overflow-hidden;

  iframe {
    min-width: 100%;
    min-height: 100%;
    width: 125vw;
    height: 75vw;
    position: absolute;
    top: 50%;
    left: 53%;
    transform: translate(-50%, -50%);

    @screen md {
      width: 104vw;
      left: 50%;
    }

    @screen lg {
      width: 104%;
    }
  }

  &__play {
    @apply absolute top-0 left-0 w-full h-full z-10 flex justify-center items-center;
  }
}
