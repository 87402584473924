@layer base {
  :root {
    --primary-color: theme(colors.primary);
    --secondary-color: theme(colors.secondary);
  }

  html {
    @apply font-serif;
  }

  body {
    @apply body-sm md:text-[22px] leading-[1.5] text-neutral antialiased;
  }

  a {
    @apply text-primary hover:text-secondary;

    text-decoration: none;
    transition: all ease 0.2s;
  }

  p {
    @apply mb-4 leading-[inherit];
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    @apply mb-2;

    & + .carousel-block,
    & + .wp-block-cover {
      @apply mt-4 md:mt-5;
    }
  }

  h1,
  .h1 {
    font-weight: 700;
    font-size: 48px;
    line-height: 1;
    letter-spacing: -2.52px;
    @screen md {
      font-size: 64px;
    }
    @screen lg {
      font-size: 84px;
    }
  }

  h2,
  .h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    @screen md {
      font-size: 46px;
      line-height: 50px;
    }
  }

  h3,
  .h3 {
    font-size: 24px;
    line-height: 36px;
    @screen md {
      font-size: 28px;
      line-height: 38px;
    }
    @screen lg {
      font-size: 32px;
      line-height: 40px;
    }

    &[class*=".h3"]:not([class*="font-"]),
    &:not([class*="font-"]) {
      font-weight: 700;
    }
  }

  h4,
  .h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    @screen md {
      font-size: 22px;
      line-height: 33px;
    }
  }

  h5,
  .h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    @screen md {
      font-size: 18px;
      line-height: 33px;
    }
  }

  h6,
  .h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
  }

  .body-2xl {
    font-size: 28px;
    line-height: 40px;
    @screen md {
      font-size: 42px;
      line-height: 45px;
    }

    &[class*="body-2xl"]:not([class*="font-"]) {
      font-weight: 200;
    }
  }

  .body-xl {
    font-size: 20px;
    line-height: 1.7;
    @screen md {
      font-size: 24px;
      line-height: 36px;
    }
  }

  .body-lg {
    font-size: 18px;
    line-height: 1.7;
    @screen md {
      font-size: 22px;
      line-height: 33px;
    }
  }

  .body-md {
    font-size: 16px;
    line-height: 1.7;
    @screen md {
      font-size: 18px;
      line-height: 27px;
    }
  }

  .body-sm {
    font-size: 14px;
    line-height: 1.7;
    @screen md {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .body-xs {
    font-size: 12px;
    line-height: 20.4px;
    @screen md {
      line-height: 18px;
    }
  }

  strong {
    @apply font-bold;
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  .page-wrap {
    position: relative;
  }

  .container {
    max-width: 1344px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.has-primary-color {
  color: var(--primary-color);
}

.has-secondary-color {
  color: var(--secondary-color);
}

.has-black-color {
  color: "#000000";
}

.has-white-color {
  color: "#ffffff";
}

.has-large-font-size {
  font-size: 28px;

  @screen md {
    font-size: 36px;
  }
}

.size-full {
  margin-left: - 1.5rem;
  margin-right: - 1.5rem;

  @screen md {
    margin-left: calc((-100vw + 100%) / 2);
    margin-right: calc((-100vw + 100%) / 2);
  }

  @media (min-width: 1384px) {
    margin-left: calc(((-100vw + 1075px) / 2));
    margin-right: calc(((-100vw + 1075px) / 2));
  }

  img {
    width: 100%;
  }
}

.size-large {
  img {
    width: 100%;
  }
}

.has-text-align-left {
  @apply text-left;
}

.has-text-align-center {
  @apply text-center;
}

.has-text-align-right {
  @apply text-right;
}

.alignleft {
  @screen md {
    float: left;
    margin-right: 2rem;
  }
}

.aligncenter {
  &,
  video {
    margin-left: auto;
    margin-right: auto;
  }
}

.alignright {
  @screen md {
    float: right;
    margin-left: 2rem;
  }
}

.wp-block-image {
  .aligncenter {
    text-align: center;

    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.wp-block-cover,
.wp-block-image {
  @apply mb-4 md:mb-8;

  &:first-child {
    @apply mb-8 md:mb-12;
  }
}

.wp-block-video {
  @apply mb-4;

  video {
    object-fit: cover;
  }

  .aligncenter {
    margin-left: auto;
    margin-right: auto;
  }
}

.wp-block-embed {
  @apply mb-4;

  .wp-block-embed__wrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.content-typo {
  h1:not([class]) {
    @apply mb-12 md:mb-20;
  }

  &--contact-intro {
    h1 {
      @apply mb-6;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    &:not([class]):not(:first-child) {
      @apply mt-6;
    }
  }

  ol {
    li {
      margin: 12px auto;
    }
  }

  ul {
    @apply list-none;
    @apply pl-[25px];

    &:not([class]) li,
    &.wp-block-list li,
    &.ioa-animate li {
      margin: 12px auto;

      @apply
        relative
        before:w-[6px] md:before:w-3 before:h-[6px] md:before:h-3
        before:absolute before:top-[11px] before:left-[-25px]
        before:rounded-full
        before:inline-block
        before:mr-3 md:before:mr-4 before:mb-[2px] md:before:mb-[1px];

      &:before {
        background-color: var(--brand-color, var(--primary-color));
      }
    }
  }
}
