.benefit {
  @apply pt-[100px] pb-[90px];

  &__block-content {
    @apply text-[18px] leading-[1.3] mb-7 md:mb-[40px];

    h2 {
      @apply text-[24px] md:text-[36px] lg:text-[45px] leading-[1.3] max-w-[324px];
    }
  }
}

.benefit-item {
  &__title {
    @apply text-[18px] md:text-[20px] leading-[1.6] flex gap-x-4 md:gap-x-6 mb-3 md:mb-5;

    span {
      @apply
        bg-[#E7E8FF] text-[20px] md:text-[22px] w-[36px] md:w-[43px] h-[36px] md:h-[43px]
        rounded-full flex items-center justify-center
        text-[#4857FF] font-bold
        translate-y-[-4px];
    }
  }

  &__content {
    @apply text-[16px];

    ul {
      @apply list-none ml-[10px] md:ml-[15px];

      li {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='12' viewBox='0 0 18 12' fill='none'%3E%3Cpath d='M6.57216 12C6.2614 12 5.95124 11.8877 5.71392 11.6624L0 6.2388L1.71648 4.60896L6.57216 9.21792L16.2835 0L18 1.62984L7.4304 11.6624C7.19308 11.8877 6.88292 12 6.57216 12Z' fill='%234857FF'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 0 8px;
        background-size: 18px 12px;
        padding-left: 36px;
        margin-bottom: 10px;

        @screen md {
          padding-left: 52px;
          margin-bottom: 15px;
        }
      }
    }
  }
}
