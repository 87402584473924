.carousel-block {
  --swiper-pagination-bullet-horizontal-gap: 3px;
  --swiper-pagination-bullet-size: 7px;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-color: #000;
  --swiper-navigation-size: 24px;
  --swiper-navigation-color: #fff;

  @screen md {
    --swiper-navigation-size: 36px;
    --swiper-pagination-bullet-size: 15px;
    --swiper-pagination-bullet-horizontal-gap: 6px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    &:hover {
      @apply text-primary;
    }
  }

  &.has-pagination {
    @apply pb-10 md:pb-[76px];

    .swiper-button-prev,
    .swiper-button-next {
      @apply -translate-y-5 md:translate-y-[-38px];
    }
  }

  .swiper-pagination {
    @apply bottom-0;
  }

  .swiper-pagination-bullet {
    @apply border border-black;
  }
}
