// Base styles with fallbacks
[style*="--"] {
  --margin-top-small: 0;
  --margin-top-medium: 0;
  --margin-top-large: 0;
  --margin-bottom-small: 0;
  --margin-bottom-medium: 0;
  --margin-bottom-large: 0;
  --padding-top-small: 0;
  --padding-top-medium: 0;
  --padding-top-large: 0;
  --padding-bottom-small: 0;
  --padding-bottom-medium: 0;
  --padding-bottom-large: 0;
}

// Mobile first
[style*="--margin-top"] {
  margin-top: var(--margin-top-small);
}
[style*="--margin-bottom"] {
  margin-bottom: var(--margin-bottom-small);
}
[style*="--padding-top"] {
  padding-top: var(--padding-top-small);
}
[style*="--padding-bottom"] {
  padding-bottom: var(--padding-bottom-small);
}

// Tablet
@screen md {
  [style*="--margin-top"] {
      margin-top: var(--margin-top-medium);
  }
  [style*="--margin-bottom"] {
      margin-bottom: var(--margin-bottom-medium);
  }
  [style*="--padding-top"] {
      padding-top: var(--padding-top-medium);
  }
  [style*="--padding-bottom"] {
      padding-bottom: var(--padding-bottom-medium);
  }
}

// Desktop
@screen lg {
  [style*="--margin-top"] {
      margin-top: var(--margin-top-large);
  }
  [style*="--margin-bottom"] {
      margin-bottom: var(--margin-bottom-large);
  }
  [style*="--padding-top"] {
      padding-top: var(--padding-top-large);
  }
  [style*="--padding-bottom"] {
      padding-bottom: var(--padding-bottom-large);
  }
}
