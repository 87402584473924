.hero-carousel {
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-size: 12px;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 0.5;
  --swiper-pagination-color: var(--secondary-color);

  @screen md {
    --swiper-pagination-bullet-size: 16px;
    --swiper-pagination-bullet-horizontal-gap: 6px;
  }

  @screen lg {
    --swiper-pagination-bullet-size: 20px;
    --swiper-pagination-bullet-horizontal-gap: 8px;
  }

  @at-root .home & {
    @apply my-12 md:my-[70px];
  }

  .hero-carousel-pagination {
    @apply bottom-3 md:bottom-5 w-auto left-1/2 -translate-x-1/2;
  }

  &__media {
    iframe {
      min-width: 100%;
      min-height: 100%;
      width: 125vw;
      height: 75vw;
      position: absolute;
      top: 50%;
      left: 53%;
      transform: translate(-50%, -50%);

      @screen md {
        width: 104vw;
        left: 50%;
      }

      @screen lg {
        width: 104%;
      }
    }

    .vp-controls {
      @apply z-10 relative;
    }
  }

  &__link {
    text-indent: -9999999px;
  }
}
