.our-services-tabs {
  @apply hidden md:block mt-[38px];

  &__item {
    @apply py-[18px] px-[41px] bg-no-repeat transition-all cursor-pointer;

    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="13" height="23" viewBox="0 0 13 23" fill="none"%3E%3Cpath d="M1.53906 21.5L12 11.5L1.53906 1.5" stroke="%23ED8130" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
    background-position: right 41px center;
    border-bottom: 2px solid #ddd;
    box-shadow: 0 0 14px 0 rgba(8, 15, 52, 0.06);

    &.is-active,
    &:hover {
      @apply border-primary text-primary;
    }

    &:not(:last-child) {
      @apply mb-[20px];
    }
  }
}

.our-services-carousel {
  --swiper-pagination-bullet-horizontal-gap: 5px;
  --swiper-pagination-bullet-size: 12px;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-color: var(--primary-color);

  .swiper {
    border-radius: 20px;
    border: 1px solid #eff0f6;
    background: #fff;
    box-shadow: 0 5px 14px 0 rgba(8, 15, 52, 0.04);

    @apply px-6 md:px-8 lg:px-[50px] pt-[30px] pb-[55px] lg:ml-[34px] lg:mr-[40px] mt-8 md:mt-0;
  }

  &__item {
    @apply bg-white;

    &.swiper-slide-prev {
      opacity: 0 !important;
    }

    h3 {
      @apply text-[#161C2D] mb-4 md:mb-[30px];
    }

    .content-typo ul {
      li {
        &::before {
          @apply bg-transparent border-primary w-[8px] h-[8px] md:w-[12px] md:h-[12px] border-[2px] md:border-[3px] top-1/2 -translate-y-1/2 md:mt-[-4px];
        }
      }
    }
  }

  .swiper-pagination {
    @apply static mt-6;
  }

  .swiper-pagination-bullet {
    border: 1px solid #b5b5b5;
  }
}
