.member-item {
  img {
    transition: all 0.4s ease-in-out;
  }

  &:hover img {
    scale: 1.25;
  }
}

.talents-carousel {
  @apply overflow-visible px-5;

  @media (min-width: 432px) {
    @apply max-w-[432px] px-0;
  }

  .swiper-wrapper {
    @apply h-[calc(100vw-(100vw/5))] items-center overflow-visible;

    @media (min-width: 432px) {
      @apply h-[364px];
    }
  }

  .swiper-slide {
    @apply opacity-40 transition-all px-2 h-auto w-[calc(100vw-132px)];

    @media (min-width: 432px) {
      @apply w-[350px];
    }

    &.swiper-slide-active {
      @apply opacity-100 w-full;

      .member-item--carousel .member-item__details {
        @apply transition-opacity delay-200 ease-in-out;
      }

      .member-item--carousel:hover .member-item__details {
        @apply opacity-100;
      }
    }
  }

  --swiper-pagination-bullet-horizontal-gap: 6px;
  --swiper-pagination-bullet-size: 12px;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-color: var(--primary-color);

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border: 1px solid #b5b5b5;

    &.swiper-pagination-bullet-active {
      @apply border-primary bg-primary;
    }
  }

  .swiper-pagination-bullet-inactive {
    width: 12px;
    height: 12px;
    background: #fff;
  }

  .swiper-pagination-horizontal {
    @apply static mt-5 md:mt-[35px];
  }
}
