.social-share {
  @apply flex items-center h-[44px] md:h-[56px];

  svg {
    @apply w-[30px] md:w-[40px] h-[30px] md:h-[40px];

    circle {
      stroke: theme('colors.neutral.DEFAULT');
    }

    path {
      fill: theme('colors.neutral.DEFAULT');
    }
  }

  &__text {
    @apply body-lg font-semibold;
  }

  &.is-active &__text {
    @apply hidden;
  }
}

.social-buttons {
  @apply hidden;

  &__item {
    @apply
      relative
      flex justify-center items-center
      w-[44px] md:w-[56px] h-[44px] md:h-[56px]
      border-r first-of-type:border-l border-t border-b border-neutral
      first-of-type:rounded-l-xl last-of-type:rounded-r-xl;

    a,
    button {
      @apply before:absolute before:top-0 before:left-0 before:w-full before:h-full;

      &:hover svg path {
        fill: var(--primary-color);
      }
    }

    svg {
      @apply w-auto h-[18px] md:h-[22px];
    }
  }
}

.social-share.is-active .social-buttons {
  @apply flex;
}
