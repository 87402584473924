/* stylelint-disable */

@charset "UTF-8";

:root :where(.wp-block-image.is-style-rounded img,.wp-block-image .is-style-rounded img) {
  border-radius: 20px !important;
}

.wp-block-gallery,
.blocks-gallery-grid {
  display: grid !important;
  grid-template-columns: repeat(1, 1fr);
  list-style-type: none;
  padding: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 24px !important;
  gap: 24px !important;

  .wp-block-image.is-style-rounded {
    @apply rounded-[20px] overflow-hidden;

    img {
      @apply rounded-[20px] #{!important};
    }
  }
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .wp-block-image,
.wp-block-gallery .blocks-gallery-item,
.blocks-gallery-grid .blocks-gallery-image,
.blocks-gallery-grid .blocks-gallery-item {
  display: block !important;
  width: auto !important;
  margin-bottom: 0 !important;
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .wp-block-image,
.wp-block-gallery .blocks-gallery-item,
.blocks-gallery-grid .blocks-gallery-image,
.blocks-gallery-grid .blocks-gallery-item {
  margin-right: 0;
  margin-left: 0;
}

@supports (position: sticky) {
  .wp-block-gallery .blocks-gallery-image figure,
.wp-block-gallery .blocks-gallery-item figure,
.blocks-gallery-grid .blocks-gallery-image figure,
.blocks-gallery-grid .blocks-gallery-item figure {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }
}
.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .wp-block-image img,
.wp-block-gallery .blocks-gallery-item img,
.blocks-gallery-grid .blocks-gallery-image img,
.blocks-gallery-grid .blocks-gallery-item img {
  display: block;
  max-width: 100%;
  height: auto;
  width: 100%;
}
@supports (position: sticky) {
  .wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .blocks-gallery-item img,
.blocks-gallery-grid .blocks-gallery-image img,
.blocks-gallery-grid .blocks-gallery-item img {
    width: auto;
  }
}
.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .wp-block-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption,
.blocks-gallery-grid .blocks-gallery-image figcaption,
.blocks-gallery-grid .blocks-gallery-item figcaption {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 3em 0.77em 0.7em;
  color: #fff;
  text-align: center;
  font-size: 0.8em;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0, rgba(0, 0, 0, 0.3) 70%, transparent);
  box-sizing: border-box;
  margin: 0;
}
.wp-block-gallery .blocks-gallery-image figcaption img,
.wp-block-gallery .blocks-gallery-item figcaption img,
.blocks-gallery-grid .blocks-gallery-image figcaption img,
.blocks-gallery-grid .blocks-gallery-item figcaption img {
  display: inline;
}
.wp-block-gallery figcaption,
.blocks-gallery-grid figcaption {
  flex-grow: 1;
}
.wp-block-gallery.is-cropped .blocks-gallery-image, .wp-block-gallery.is-cropped .blocks-gallery-item,
.blocks-gallery-grid.is-cropped .blocks-gallery-image,
.blocks-gallery-grid.is-cropped .blocks-gallery-item {
  align-self: inherit;
}
.wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img, .wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .blocks-gallery-item img,
.blocks-gallery-grid.is-cropped .blocks-gallery-image a,
.blocks-gallery-grid.is-cropped .blocks-gallery-image img,
.blocks-gallery-grid.is-cropped .blocks-gallery-item a,
.blocks-gallery-grid.is-cropped .blocks-gallery-item img {
  width: 100%;
}
@supports (position: sticky) {
  .wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img, .wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .blocks-gallery-item img,
.wp-block-gallery.is-cropped .wp-block-image a,
.wp-block-gallery.is-cropped .wp-block-image img,
.blocks-gallery-grid.is-cropped .blocks-gallery-image a,
.blocks-gallery-grid.is-cropped .blocks-gallery-image img,
.blocks-gallery-grid.is-cropped .blocks-gallery-item a,
.blocks-gallery-grid.is-cropped .blocks-gallery-item img {
    height: 100%;
    flex: 1;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.wp-block-gallery.columns-1 .blocks-gallery-image,
.wp-block-gallery.columns-1 .blocks-gallery-item,
.wp-block-gallery.columns-1 .wp-block-image,
.blocks-gallery-grid.columns-1 .blocks-gallery-image,
.blocks-gallery-grid.columns-1 .blocks-gallery-item {
  width: 100%;
  margin-right: 0;
}

.wp-block-gallery,
.blocks-gallery-grid {
  &.columns-2 {
    @screen sm {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &.columns-3 {
    @screen sm {
      grid-template-columns: repeat(2, 1fr);
    }

    @screen md {
      grid-template-columns: repeat(3, 1fr);
    }

    .wp-block-image {
      &:first-child {
        @apply col-span-full;
        @screen md {
          @apply col-span-1;
        }
      }
    }
  }

  &.columns-4 {
    @screen sm {
      grid-template-columns: repeat(2, 1fr);
    }

    @screen md {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  // &.columns-5 {
  //   grid-template-columns: repeat(2, 1fr);
  //   @screen sm {
  //     grid-template-columns: repeat(5, 1fr);
  //   }
  // }
  // &.columns-6 {
  //   grid-template-columns: repeat(2, 1fr);
  //   @screen sm {
  //     grid-template-columns: repeat(6, 1fr);
  //   }
  // }
  // &.columns-7 {
  //   grid-template-columns: repeat(2, 1fr);
  //   @screen sm {
  //     grid-template-columns: repeat(7, 1fr);
  //   }
  // }
  // &.columns-8 {
  //   grid-template-columns: repeat(2, 1fr);
  //   @screen sm {
  //     grid-template-columns: repeat(8, 1fr);
  //   }
  // }
}

.wp-block-gallery.alignleft, .wp-block-gallery.alignright,
.blocks-gallery-grid.alignleft,
.blocks-gallery-grid.alignright {
  max-width: 420px;
  width: 100%;
}
.wp-block-gallery.aligncenter .blocks-gallery-item figure,
.blocks-gallery-grid.aligncenter .blocks-gallery-item figure {
  justify-content: center;
}

/* stylelint-enable */
