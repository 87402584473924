.rating-stars {
  @apply flex;

  svg {
    @apply w-[22px] md:w-[34px] h-[22px] md:h-[34px];

    &:not(:last-child) {
      @apply mr-[4px] md:mr-[6px];
    }

    path {
      fill: rgba(#666, 0.6);
    }
  }

  &--1 svg:nth-child(-n+1) path {
    fill: var(--primary-color);
  }

  &--2 svg:nth-child(-n+2) path {
    fill: var(--primary-color);
  }

  &--3 svg:nth-child(-n+3) path {
    fill: var(--primary-color);
  }

  &--4 svg:nth-child(-n+4) path {
    fill: var(--primary-color);
  }

  &--5 svg:nth-child(-n+5) path {
    fill: var(--primary-color);
  }
}
