.brief-history {
  @apply
    bg-[#f9f9f9]
    pt-[40px] md:pt-[80px] lg:pt-[121px] pb-[40px] md:pb-[80px] lg:pb-[122px];

  &__title {
    @apply
      text-[24px] md:text-[45px] lg:text-[58px]
      font-extrabold pb-[20px] md:pb-[30px] lg:pb-[49px];
  }
}

.brief-history-item {
  @apply
    relative
    pl-[48px] md:pl-[77px]
    pb-[28px] md:pb-[36px];

  &:not(:last-child)::before {
    content: "";

    @apply
      bg-primary
      absolute left-0 top-[12px] md:top-[20px]
      w-[1px] h-full;
  }

  &__title {
    @apply text-[18px] md:text-[24px] lg:text-[30px] font-extrabold leading-[130%] relative mb-[3px];

    &::after,
    &::before {
      content: "";

      @apply mr-[11px] md:mr-[22px];
    }

    &::before {
      @apply
        bg-primary h-[1px] w-[37px] md:w-[55px]
        absolute right-full top-[12px] md:top-[20px];
    }

    &::after {
      @apply
        w-[6px] md:w-[18px] h-[6px] md:h-[18px]
        bg-primary
        rounded-full
        absolute right-full top-[10px] md:top-[12px];
    }
  }

  &__brief {
    @apply text-[16px] md:text-[20px] lg:text-[22px] text-neutral leading-[130%];
  }
}
