.js-headroom {
  will-change: transform;
  transition: transform 200ms linear;
}

.headroom--pinned.js-headroom {
  transform: translateY(0%);

  &:not(.headroom--top) {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
  }
}

.headroom--unpinned.js-headroom {
  transform: translateY(-100%);
}
