.instagram-gallery-feed {
  --swiper-pagination-bullet-horizontal-gap: 3px;
  --swiper-pagination-bullet-size: 12px;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-color: #000;
  --swiper-navigation-size: 18px;
  --swiper-navigation-color: #fff;

  @screen sm {
    --swiper-navigation-size: 20px;
  }

  @screen md {
    --swiper-navigation-size: 36px;
    --swiper-pagination-bullet-size: 15px;
    --swiper-pagination-bullet-horizontal-gap: 6px;
    --swiper-navigation-size: 30px;
  }

  .swiper-container {
    @apply overflow-hidden relative;
  }

  .swiper-button-prev,
  .swiper-button-next {
    @apply bg-[#1c1c1c] absolute z-10 h-[48px] w-[48px] sm:w-[60px] sm:h-[60px] md:w-[90px] md:h-[90px] lg:h-[116px] lg:w-[116px] rounded-full translate-y-[-50%] mt-0;
  }

  .swiper-button-prev {
    @apply left-0 translate-x-[-50%];

    &::after {
      @apply ml-4 sm:ml-6 md:ml-8 lg:ml-12;
    }
  }

  .swiper-button-next {
    @apply right-0 translate-x-[50%];

    &::after {
      @apply mr-4 sm:mr-6 md:mr-8 lg:mr-12;
    }
  }

  .swiper-pagination {
    @apply static mt-[10px] md:hidden;
  }

  .swiper-pagination-bullet {
    @apply border border-[#b5b5b5];
  }

  .swiper-pagination-bullet {
    &.swiper-pagination-bullet-active {
      @apply bg-primary;
    }
  }

  .instagram-gallery-item {
    @apply rounded-[20px] overflow-hidden;
  }
}
