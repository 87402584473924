@tailwind base;
@tailwind components;
@tailwind utilities;


@import "common/variables";

/**
* Import npm dependencies
*
* Prefix your imports with `~` to grab from node_modules/
* @see https://github.com/webpack-contrib/sass-loader#imports
*/


@import "../../node_modules/swiper/swiper.scss";
@import "../../node_modules/swiper/modules/pagination/pagination.scss";
@import "../../node_modules/swiper/modules/navigation/navigation.scss";
@import "../../node_modules/mediabox/dist/mediabox.css";

// Import fonts
@import "common/animation";

/** Import theme styles */
@import "common/base";
@import "common/utilities";
@import "common/buttons";
@import "common/spacing-options";
@import "layouts/header";
@import "layouts/footer";
@import "modules/off-canvas";
@import "modules/headroom";
@import "modules/hero-carousel";
@import "modules/clients-carousel";
@import "modules/instagram-carousel";
@import "modules/mailchimp";
@import "modules/vimeo";
@import "modules/trending-articles";
@import "modules/our-services";
// @import "modules/kulea-subscription-form";
// @import "modules/kulea-contact-form";
@import "modules/latest-works";
@import "modules/inspiration";
@import "modules/quote-simple";
@import "modules/testimonial-carousel";
@import "modules/rating-stars";
@import "modules/brief-history";
@import "modules/social-share";
@import "modules/tooltip";
@import "modules/team";
@import "modules/newsletter";
@import "modules/benefit";
@import "modules/our-services";
@import "modules/we-work-with";
@import "modules/about";
@import "modules/cta";
@import "modules/scroll-to-top";
@import "modules/showreel";
@import "blocks/default-gallery-blocks";
@import "blocks/testimonial-block";
@import "blocks/carousel-block";
@import "blocks/video";
@import "blocks/image";
@import "plugins/ajax-load-more";
@import "blocks/media-and-text";
@import "blocks/objectives-list";
