.menu-footer-navigation {
  @apply flex flex-wrap list-none gap-x-[40px] overflow-hidden;

  .menu-item {
    @apply relative;

    &::before {
      content: "|";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: calc(100% + 20px);
      color: white;
      font-size: 12px;
      line-height: 1;
      margin-top: 2px;
    }
  }

  a {
    @apply text-white text-[12px] leading-[32px];
  }
}
