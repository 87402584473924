.latest-works {
  --swiper-pagination-bullet-horizontal-gap: 6px;
  --swiper-pagination-bullet-size: 12px;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-color: var(--primary-color);

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border: 1px solid #b5b5b5;

    &.swiper-pagination-bullet-active {
      @apply border-primary bg-primary;
    }
  }

  .swiper-pagination-bullet-inactive {
    width: 12px;
    height: 12px;
    background: #fff;
  }

  .swiper-pagination-horizontal {
    position: static;
  }

  &__nav {
    @apply cursor-pointer;

    &.swiper-button-disabled {
      @apply cursor-not-allowed opacity-50;
    }
  }
}
