.scroll-to-top {
  @apply fixed w-[46px] h-[46px] md:w-[70px] md:h-[70px] flex items-center justify-center bg-white z-50;
  @apply right-[24px] md:right-[32px] lg:right-[72px];

  border-radius: 70px;
  border: 1px solid #eff0f6;
  box-shadow: 0 5px 14px 0 rgba(8, 15, 52, 0.04);
  transition: bottom 0.3s ease-in-out;
  bottom: -80px;
  cursor: pointer;

  &.is-active {
    @apply bottom-[32px] md:bottom-[80px] lg:bottom-[244px];
  }

  svg {
    @apply w-[18px] h-[18px] md:w-[26px] md:h-[26px];
  }
}
