$distance: 50px !default;

[data-ioa-animation^="fade"][data-ioa-animation^="fade"],
.js-content-animation > * {
  opacity: 0;
  transition-property: opacity, transform;

  &.ioa-animate {
    opacity: 1;
    transform: translateZ(0);
  }
}

[data-ioa-animation],
.js-content-animation > * {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  will-change: transform, opacity;

  &.ioa-animate {
    opacity: 1;
    transform: translateY(0);
  }
}

[data-ioa-animation="fade-up"],
.js-content-animation > * {
  transform: translate3d(0, $distance, 0);
}

[data-ioa-animation="fade-down"] {
  transform: translate3d(0, -$distance, 0);
}

[data-ioa-animation="fade-right"] {
  transform: translate3d(-$distance, 0, 0);
}

[data-ioa-animation="fade-left"] {
  transform: translate3d($distance, 0, 0);
}

[data-ioa-animation="fade-up-right"] {
  transform: translate3d(-$distance, $distance, 0);
}

[data-ioa-animation="fade-up-left"] {
  transform: translate3d($distance, $distance, 0);
}

[data-ioa-animation="fade-down-right"] {
  transform: translate3d(-$distance, -$distance, 0);
}

[data-ioa-animation="fade-down-left"] {
  transform: translate3d($distance, -$distance, 0);
}

/**
    * Zoom animations:
    * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
    * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
    */

[data-ioa-animation^="zoom"][data-ioa-animation^="zoom"] {
  opacity: 0;
  transition-property: opacity, transform;

  &.ioa-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

[data-ioa-animation="zoom-in"] {
  transform: scale(0.6);
}

[data-ioa-animation="zoom-in-up"] {
  transform: translate3d(0, $distance, 0) scale(0.6);
}

[data-ioa-animation="zoom-in-down"] {
  transform: translate3d(0, -$distance, 0) scale(0.6);
}

[data-ioa-animation="zoom-in-right"] {
  transform: translate3d(-$distance, 0, 0) scale(0.6);
}

[data-ioa-animation="zoom-in-left"] {
  transform: translate3d($distance, 0, 0) scale(0.6);
}

[data-ioa-animation="zoom-out"] {
  transform: scale(1.2);
}

[data-ioa-animation="zoom-out-up"] {
  transform: translate3d(0, $distance, 0) scale(1.2);
}

[data-ioa-animation="zoom-out-down"] {
  transform: translate3d(0, -$distance, 0) scale(1.2);
}

[data-ioa-animation="zoom-out-right"] {
  transform: translate3d(-$distance, 0, 0) scale(1.2);
}

[data-ioa-animation="zoom-out-left"] {
  transform: translate3d($distance, 0, 0) scale(1.2);
}

/**
    * Slide animations
    */

[data-ioa-animation^="slide"][data-ioa-animation^="slide"] {
  transition-property: transform;
  visibility: hidden;

  &.ioa-animate {
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
}

[data-ioa-animation="slide-up"] {
  transform: translate3d(0, 100%, 0);
}

[data-ioa-animation="slide-down"] {
  transform: translate3d(0, -100%, 0);
}

[data-ioa-animation="slide-right"] {
  transform: translate3d(-100%, 0, 0);
}

[data-ioa-animation="slide-left"] {
  transform: translate3d(100%, 0, 0);
}

/**
    * Flip animations:
    * flip-left, flip-right, flip-up, flip-down
    */

[data-ioa-animation^="flip"][data-ioa-animation^="flip"] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-ioa-animation="flip-left"] {
  transform: perspective(2500px) rotateY(-100deg);

  &.ioa-animate {
    transform: perspective(2500px) rotateY(0);
  }
}

[data-ioa-animation="flip-right"] {
  transform: perspective(2500px) rotateY(100deg);

  &.ioa-animate {
    transform: perspective(2500px) rotateY(0);
  }
}

[data-ioa-animation="flip-up"] {
  transform: perspective(2500px) rotateX(-100deg);

  &.ioa-animate {
    transform: perspective(2500px) rotateX(0);
  }
}

[data-ioa-animation="flip-down"] {
  transform: perspective(2500px) rotateX(100deg);

  &.ioa-animate {
    transform: perspective(2500px) rotateX(0);
  }
}

@layer utilities {
  .case-study-list {
    &[data-children-count="even"] {
      padding-bottom: 340px;
    }

    &[data-children-count="odd"] {
      padding-bottom: 80px;
    }

    > [data-ioa-animation] {
      &:nth-child(even) {
        position: relative;
        top: 56.5%;
      }
    }
  }
}
