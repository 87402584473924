.clients-carousel {
  --swiper-pagination-bullet-horizontal-gap: 5px;
  --swiper-pagination-bullet-size: 12px;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-color: var(--primary-color);

  &__wrapper {
    @apply mx-auto md:grid grid-cols-2 lg:grid-cols-4 md:gap-x-6 md:gap-y-10;
  }

  &--vietnam &__wrapper {
    @apply mx-auto md:grid grid-cols-2 lg:grid-cols-5 md:gap-8 lg:gap-10;
  }

  &__item {
    @apply border border-neutral md:border-0 flex items-center h-auto px-8 md:px-0;

    &:not(:last-child) {
      @apply border-r-0;
    }
  }

  .swiper-pagination {
    @apply static mt-4;
  }

  .swiper-pagination-bullet {
    border: 1px solid #b5b5b5;
  }
}
