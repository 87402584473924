

.newsletter {
  max-width: 858px;
  margin: 0 auto;

  &:not(&--popup) {
    text-align: center;
  }

  &--popup {
    position: fixed;
    top: 100%;
    right: 0;
    z-index: 10;
    padding-right: 24px;
    padding-left: 24px;
    transform: translateY(0);
    transition: transform 1s ease;

    @apply px-6 lg:px-[71px] md:max-w-[575px] lg:max-w-[669px];

    &.is-open {
      transform: translateY(calc(-100% - 24px));
    }
  }

  &__inner {
    position: relative;
  }

  &--popup &__inner {
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    padding: 40px 24px;
  }

  &__title {
    @apply text-[28px] md:text-[45px] font-bold leading-[1.1] mb-6;
  }

  &--popup &__title {
    @apply pr-7 text-[24px] md:text-[30px];
  }

  &__text {
    @apply text-[20px] md:text-[22px] mb-4 md:mb-8;
  }

  &__input {
    border: 1px solid #747c8b;
    width: 100%;
    height: 52px;
    border-radius: 10px;
    padding: 10px;
    font-size: 16px;
  }

  &__button {
    @apply bg-primary hover:bg-primary hover:bg-opacity-70 transition-all rounded-[10px] text-white text-[18px] leading-5 w-full py-[16px];
  }

  &__close {
    position: absolute;
    top: 40px;
    right: 24px;
  }

  &__thanks {
    @apply hidden font-medium;
  }

  &--popup &__thanks {
    @apply pt-8 text-[20px];
  }

  &--success &__thanks {
    @apply block;

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  &--success &__form {
    @apply hidden;
  }

  &--success#{&}--popup &__signup {
    @apply hidden;
  }

  &:not(&--popup) &__form {
    max-width: 520px;
    margin: 0 auto;
  }
}
