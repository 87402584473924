#mc-embedded-subscribe-form {
  padding-left: 0 !important;
}

#mc_embed_signup_scroll {
  display: flex;
  flex-wrap: wrap;

  @screen md {
    flex-wrap: nowrap;
  }

  @at-root .mailchimp-front-page {
    margin-left: -5px;
    margin-right: -5px;
  }

  > h2 {
    display: none;
  }

  @at-root .mailchimp-front-page & {
    .mc-field-group {
      padding-left: 5px !important;
      padding-right: 5px !important;
      padding-bottom: 24px;
      width: 100%;

      > label {
        height: 24px;
      }

      @screen md {
        width: 50%;
      }

      @screen lg {
        padding-bottom: 0;
        width: auto;
        flex: 1 1 0;
      }

      input {
        &[type="email"] {
          font-size: 16px;
          border-radius: 10px;
          margin-top: 8px;
          padding: 17px 0;
          color: #747c8b;
        }
      }
    }

    .indicates-required {
      display: none;
    }

    .indicates-required + .mc-field-group {
      @screen md {
        width: 100%;
      }
    }
  }

  @at-root .mailchimp-front-page & {
    .clear {
      display: none;
      padding-left: 5px;
      padding-right: 5px;

      @screen md {
        margin-top: 32px;
        flex: 0 0 auto;
        width: auto;
      }

      &:last-child {
        display: block;
        width: 100%;

        @screen md {
          width: auto;
        }
      }
    }
  }

  .indicates-required {
    text-align: left !important;
    margin-bottom: 10px;
    width: 100%;
  }

  #mc-embedded-subscribe.button {
    @apply bg-primary block text-[20px] md:text-[28px] py-[12px] px-5 md:px-[20px] hover:bg-opacity-80;

    height: auto;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    width: 100%;

    @screen md {
      width: auto;
    }
  }
}
