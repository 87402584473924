.media-and-text {
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mb-3 md:mb-5;
  }

    .body-xl {
      font-size: 19px;
      line-height: 1.7;

      @screen md {
        font-size: 23px;
        line-height: 1.5;
      }
    }

  ul:not([class])  {
    @apply pl-[30px] list-outside list-disc;

    li {
      @apply my-3 md:my-5 text-[17px] md:text-[21px] leading-[1.7] md:leading-[1.5];

      &::before {
        @apply hidden;
      }

      &::marker {
        color: var(--brand-color);
      }
    }
  }

  img {
    @apply rounded-[20px];
  }
}
