.js-hero-carousel-video {
  height: 100%;

  > iframe {
    width: 100%;
    height: 100%;
  }
}

.js-audio-controller {
  width: 20px;
  height: 20px;

  .icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
  }

  &:not(.is-unmuted) .volume-off {
    display: block;
  }

  &.is-unmuted .volume-up {
    display: block;
  }
}
