.alm-reveal {
  @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-8;
}

.alm-filters-container {
  @apply flex lg:justify-end;

  margin-bottom: 0 !important;
}

.alm-filter--inner {
  padding-bottom: 0 !important;

  ul {
    @apply flex flex-wrap lg:justify-end list-none -mx-2 md:-mx-4;
  }
}

.alm-filter--checkbox,
.alm-filter--radio,
.alm-filters--controls {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
  margin-bottom: 12px !important;

  @screen md {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    margin-bottom: 16px !important;
  }

  .alm-filter--link,
  .alm-filters--reset-button {
    @apply border border-primary rounded-[10px] body-sm md:text-[18px] font-bold leading-[1.5] text-black py-2 md:py-[11px] px-4 md:px-6 block hover:text-white hover:bg-primary cursor-pointer;

    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;

    @screen md {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
      padding-top: 11px !important;
      padding-bottom: 11px !important;
    }

    &::before,
    &::after {
      display: none !important;
    }
  }

  .alm-filter--link.active,
  .alm-filters--reset-button.hidden {
    @apply bg-primary text-white;
  }
}

.alm-filters--controls {
  margin-right: 0 !important;
}

.alm-btn-wrap .alm-load-more-btn {
  background-color: var(--primary-color) !important;
}
