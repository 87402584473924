.testimonial-carousel {
  --swiper-pagination-bullet-horizontal-gap: 5px;
  --swiper-pagination-bullet-size: 12px;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-color: var(--primary-color);

  .swiper-pagination {
    @apply static mt-6 md:hidden;

    .swiper-pagination-bullet {
      border: 1px solid #b5b5b5;
    }
  }

  .swiper-slide {
    &.swiper-slide-active {
      @apply z-10;
    }

    &:not(.swiper-slide-active) {
      @apply opacity-0 #{!important};
    }
  }
}

.testimonial-author-list {
  @apply
    md:mx-0 md:px-0
    hidden md:block;
}

.testimonial-author-item {
  @apply
    flex
    rounded-[32px]
    px-[14px] md:px-[32px] py-[24px] md:py-[30px]
    md:mb-[48px]
    relative z-10
    transition-all;

  &:hover {
    box-shadow: 0 3px 30px 3px #e3e3e3;
  }

  @at-root .is-active & {
    box-shadow: 0 3px 30px 3px #e3e3e3;
  }

  .swiper-slide & {
    @apply shadow-2xl shadow-[#e3e3e3];
  }

  &__media {
    @apply w-[110px] h-[110px] rounded-full overflow-hidden mr-[9px] md:mr-[32px] flex-shrink-0 bg-center bg-cover;

    img {
      @apply w-full h-full object-cover;
    }
  }

  &__content {
    @apply mt-[7px] max-w-[244px];
  }

  &__name {
    @apply
      text-[18px] md:text-[30px]
      font-semibold leading-[1.5] text-neutral
      mb-[6px];
  }

  &__jobtitle {
    @apply text-[14px] md:text-[18px] leading-[1.3];
  }

  &__company {
    @apply text-[14px] md:text-[18px] leading-[1.3];
  }
}
