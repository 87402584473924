@layer utilities {
  .mt-xs {
    @apply mt-[10px];
  }

  .mt-sm {
    @apply mt-[15px] md:mt-[25px];
  }

  .mt-md {
    @apply mt-[20px] md:mt-[35px];
  }

  .mt-lg {
    @apply mt-[35px] md:mt-[56px] lg:mt-[70px];
  }

  .mt-xl {
    @apply mt-[70px] md:mt-[90px] lg:mt-[140px];
  }

  .mb-xs {
    @apply mb-[10px];
  }

  .mb-sm {
    @apply mb-[15px] md:mb-[25px];
  }

  .mb-md {
    @apply mb-[20px] md:mb-[35px];
  }

  .mb-lg {
    @apply mb-[35px] md:mb-[56px] lg:mb-[70px];
  }

  .mb-xl {
    @apply mb-[70px] md:mb-[90px] lg:mb-[140px];
  }

  .my-xs {
    @apply mt-xs mb-xs;
  }

  .my-sm {
    @apply mt-sm mb-sm;
  }

  .my-md {
    @apply mt-md mb-md;
  }

  .my-lg {
    @apply mt-lg mb-lg;
  }

  .my-xl {
    @apply mt-xl mb-xl;
  }

  .pt-xs {
    @apply pt-[10px];
  }

  .pt-sm {
    @apply pt-[15px] md:pt-[25px];
  }

  .pt-md {
    @apply pt-[20px] md:pt-[35px];
  }

  .pt-lg {
    @apply pt-[35px] md:pt-[56px] lg:pt-[70px];
  }

  .pt-xl {
    @apply pt-[70px] md:pt-[90px] lg:pt-[140px];
  }

  .pb-xs {
    @apply pb-[10px];
  }

  .pb-sm {
    @apply pb-[15px] md:pb-[25px];
  }

  .pb-md {
    @apply pb-[20px] md:pb-[35px];
  }

  .pb-lg {
    @apply pb-[35px] md:pb-[56px] lg:pb-[70px];
  }

  .pb-xl {
    @apply pb-[70px] md:pb-[90px] lg:pb-[140px];
  }

  .py-xs {
    @apply pt-xs pb-xs;
  }

  .py-sm {
    @apply pt-sm pb-sm;
  }

  .py-md {
    @apply pt-md pb-md;
  }

  .py-lg {
    @apply pt-lg pb-lg;
  }

  .py-xl {
    @apply pt-xl pb-xl;
  }
}

.hide-large-up {
  @media (min-width: 1024px) {
    display: none;
  }
}

.truncated-title {
  @apply max-h-[82px] overflow-hidden relative after:content-["..."] after:absolute after:right-0 after:bottom-0;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.text-ellipsis-2 {
  @extend .text-ellipsis;

  -webkit-line-clamp: 2;
}

.video-responsive {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
}

.video-responsive::before {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.video-responsive-item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

// protected posts/pages message box customization
form.post-password-form {
  margin-top: 90px;
  max-width: 600px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 90px;
  background-color: #fff;
  padding: 30px;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
  border: 1px solid #ffd0a5;

  label {
    margin-right: 20px;
    display: block;
    max-width: 250px;
  }

  input[type=password] {
    border: 1px solid #aeaeae;
    border-radius: 5px;
    font-size: 24px;
    font-family: initial;
    padding-left: 5px;
    display: block;
  }

  input[type=submit] {
    @apply border-opacity-70 text-[16px] sm:text-[18px] md:text-[20px] font-semibold text-white inline-block px-[20px] md:px-[28px] py-[5px] sm:py-[9px] md:py-[6px] mt-[20px] rounded-[5px] leading-[1.5] bg-primary hover:bg-opacity-80 cursor-pointer transition-all;
  }
}
