.we-work-with {
  .swiper {
    --swiper-pagination-bullet-horizontal-gap: 6px;
    --swiper-pagination-bullet-size: 10px;
    --swiper-pagination-bullet-inactive-color: #ddd;
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-color: var(--tertiary-color);

    @screen md {
      --swiper-pagination-bullet-horizontal-gap: 12px;
      --swiper-pagination-bullet-size: 15px;
    }
  }

  .swiper-pagination {
    @apply flex items-center justify-center mt-[-12px];
  }

  .swiper-pagination-bullet-active {
    @apply w-[12px] h-[12px] md:w-[20px] md:h-[20px];
  }

  .content-typo {
    @apply text-[16px];

    h3 {
      @apply text-[18px] md:text-[24px] mb-[24px] md:mb-[30px];
    }

    a {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15' fill='none'%3E%3Cpath d='M13.9996 1L1.79102 13.3392' stroke='%23ED8130' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14 13.0543V1H2.07678' stroke='%23ED8130' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 15px;
      padding-right: 20px;
    }

    .text-tertiary a {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15' fill='none'%3E%3Cpath d='M13.7613 1L1.55273 13.3392' stroke='%234856FF' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M13.7616 13.0543V1H1.83838' stroke='%234856FF' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
  }
}
