.single-casestudy,
.single-private-casestudy {
  .wp-block-video,
  .wp-block-embed {
    @apply max-w-[856px] mx-auto rounded-[20px] overflow-hidden;
  }
}

.wp-block-video .audio-btn {
  @apply bottom-4;
}

.wp-block-cover .audio-btn {
  @apply bottom-8;
}

.wp-block-video,
.wp-block-cover {
  @apply relative table;

  .audio-btn {
    @apply cursor-pointer w-[40px] h-[40px] md:w-[44px] md:h-[44px] bg-black bg-opacity-60 absolute right-4 bg-no-repeat bg-center rounded-full;

    background-size: 22px auto;

    @screen md {
      background-size: 24px auto;
    }

    &.unmuted {
      background-image: url(../../icons/volume-2.svg);
    }

    &:not(.unmuted) {
      background-image: url(../../icons/volume-x.svg);
    }
  }
}
