/* stylelint-disable */
.page-wrap,
.off-canvas {
  transition: transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
}

.off-canvas {
  position: fixed;
  top: 0;
  left: 100%;
  width: 76%;
  height: 100%;
  z-index: 11;
  transform: translateX(0);
  box-shadow: inset 0px 0px 10px rgba(255, 255, 255, 0.30);

  @apply bg-black;

  .off-canvas-nav {
    height: 100vh;
    height: stretch;
  }

  @at-root .is-off-canvas-open & {
    transform: translateX(-100%);
  }

  .menu-items {
    list-style-type: none;
  }

  .menu-item {
    a {
      @apply text-white font-bold text-[16px] sm:text-[18px] border-b border-white border-opacity-30 block px-[16px] py-[14px];
    }
  }
}

body.is-off-canvas-open  {
  overflow: hidden;
}

.is-off-canvas-open {

  .page-wrap {
    transform: translateX(-76%);
    height: 100%;

    &::after {
      content: '';
      background-color: rgba(0, 0, 0, 0.6);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
}
/* stylelint-enable */
