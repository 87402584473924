@layer components {
  .btn {
    --btn-bg: theme('colors.black');
    --btn-hover-bg: theme('colors.primary');
    --btn-active-bg: theme('colors.neutral.dark.600');
    --btn-disabled-bg: theme('colors.neutral.dark.300');
    --btn-color: theme('colors.white');
    --btn-hover-color: theme('colors.white');
    --btn-active-color: theme('colors.white');
    --btn-disabled-color: theme('colors.white');
    --btn-font-size: 16px;
    --btn-font-weight: theme('fontWeight.medium');
    --btn-line-height: 1.2;
    --btn-border-radius: 50px;
    --btn-padding-x: 20px;
    --btn-padding-y: 10px;
    --btn-shadow: none;
    --btn-text-decoration: none;

    background-color: var(--btn-bg);
    color: var(--btn-color);
    font-size: var(--btn-font-size);
    font-weight: var(--btn-font-weight);
    line-height: var(--btn-line-height);
    border-radius: var(--btn-border-radius);
    padding-top: var(--btn-padding-y);
    padding-bottom: var(--btn-padding-y);
    padding-left: var(--btn-padding-x);
    padding-right: var(--btn-padding-x);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    box-shadow: var(--btn-shadow);
    text-decoration: var(--btn-text-decoration);
    transition: all 0.3s ease;

    svg {
      fill: var(--btn-color);
      transition: all 0.3s ease;
    }

    &:hover {
      background-color: var(--btn-hover-bg);
      color: var(--btn-hover-color);

      svg {
        fill: var(--btn-hover-color);
      }
    }

    &:active {
      background-color: var(--btn-active-bg);
      color: var(--btn-active-color);
    }

    &:disabled {
      background-color: var(--btn-disabled-bg);
      color: var(--btn-disabled-color);
    }



    @screen md {
      --btn-padding-x: 30px;
      --btn-padding-y: 16px;
      --btn-font-size: 20px;
    }
  }

  .btn-secondary {
    --btn-bg: theme('colors.transparent');
    --btn-hover-bg: theme('colors.neutral.gray.100');
    --btn-active-bg: theme('colors.neutral.gray.100');
    --btn-disabled-bg: theme('colors.transparent');
    --btn-color: theme('colors.neutral.gray.700');
    --btn-hover-color: theme('colors.neutral.gray.700');
    --btn-active-color: theme('colors.neutral.gray.700');
    --btn-disabled-color: theme('colors.neutral.dark.300');
    --btn-shadow: inset 0 0 0 1px theme('colors.neutral.gray.700');
  }

  .btn-link {
    --btn-bg: theme('colors.transparent');
    --btn-hover-bg: theme('colors.transparent');
    --btn-active-bg: theme('colors.transparent');
    --btn-disabled-bg: theme('colors.transparent');
    --btn-color: theme('colors.primary');
    --btn-hover-color: theme('colors.neutral.DEFAULT');
    --btn-active-color: theme('colors.secondary');
    --btn-disabled-color: theme('colors.neutral.dark.300');
    --btn-padding-x: 0;
    --btn-padding-y: 0;
    --btn-font-size: 22px;
    --btn-font-weight: theme('fontWeight.semibold');
    --btn-text-decoration: none;

    &--white {
      --btn-color: theme('colors.white');
      --btn-hover-color: theme('colors.neutral.DEFAULT');
    }
  }
}
