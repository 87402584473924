.quote-simple {
  @apply container pt-16 lg:pt-[120px] mb-10 lg:mb-28;

  &__quote {
    @apply max-w-[878px] m-auto flex justify-center;

    blockquote {
      @apply font-semibold text-neutral text-[24px] sm:text-[32px] md:text-[36px] lg:text-[45px] leading-[1.3] tracking-wide;
    }
  }
}
