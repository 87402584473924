.cta {
  @apply bg-primary relative overflow-hidden;

  &__title {
    @apply mb-sm;
  }

  &__text {
    @apply mb-sm body-md font-semibold;

    a {
      @apply text-white hover:underline;
    }
  }

  &__inner {
    @apply lg:relative py-lg;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 258px;
      height: 258px;
      border: 25px solid theme('colors.white');
      border-radius: 50%;
      background-color: theme('colors.neutral.DEFAULT');
      @screen xl {
        width: 282px;
        height: 282px;
      }
    }

    &::before {
      left: 100%;
      top: 0;
      transform: translateX(-30%) translateY(-64%);
      @screen md {
        transform: translateX(-52%) translateY(-56%);
      }
      @screen lg {
        transform: translateX(-64%) translateX(-35px) translateY(-56px);
      }
      @screen xl {
        transform: translateX(-100%) translateX(-35px) translateY(-56px);
      }
    }

    &::after {
      bottom: 0;
      left: 100%;
      transform: translateX(-100%) translateX(36px) translateY(74%);
      @screen lg {
        transform: translateX(-64%) translateX(-221px) translateY(56px);
      }
      @screen xl {
        transform: translateX(-100%) translateX(-292px) translateY(53px);
      }
    }
  }
}
