.primary-nav {
   .menu-items {
    @apply flex list-none gap-x-[36px] xl:gap-x-[50px];

    .menu-item {

      a {
        @apply text-white hover:text-primary font-medium text-[18px] leading-[1.5] block;
      }

      &.current-menu-item a {
        @apply text-primary;
      }
    }
  }
}

.icon-hamburger {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 20px;
  z-index: 11;

  @screen sm {
    width: 32px;
    height: 26px;
  }

  &__inner {
    &::before {
      transition: top 0.1s ease-in 0.25s, opacity 0.1s ease-in;
      top: -8px;

      @screen sm {
        top: -09px;
      }

      @at-root .is-off-canvas-open & {
        top: 0;
        transition: top 0.1s ease-out, opacity 0.1s ease-out 0.12s;
        opacity: 0;
      }
    }

    &::after {
      transition: bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      bottom: -8px;

      @screen sm {
        bottom: -09px;
      }

      @at-root .is-off-canvas-open & {
        bottom: 0;
        transition: bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
        transform: rotate(-90deg);
      }
    }

    &::before,
    &::after {
      content: "";
    }

    &,
    &::before,
    &::after {
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      transition-timing-function: ease;
      transition-duration: 0.15s;
      transition-property: transform;

      @apply bg-white;
    }

    @at-root .is-off-canvas-open & {
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: rotate(225deg);
    }
  }
}
