.block-image {

  &:not([style*="--margin-bottom-small"], [style*="--margin-bottom-medium"], [style*="--margin-bottom-large"]) {
    @apply mb-sm;
  }

  &--empty &__image {
    @apply bg-gray-300 min-h-[260px];
  }

  @at-root .container &--fluid &__image {
    margin-left: - 1.5rem;
    margin-right: - 1.5rem;

    @screen md {
      margin-left: calc((-100vw + 100%) / 2);
      margin-right: calc((-100vw + 100%) / 2);
    }

    @media (min-width: 1384px) {
      margin-left: calc(((-100vw + 1296px) / 2));
      margin-right: calc(((-100vw + 1296px) / 2));
    }

    img {
      width: 100%;
    }
  }

  @at-root .container &--container &__image {
    img {
      @apply w-full;
    }
  }

  &--left &__image {
    img {
      @apply mr-auto;
    }
  }

  &--centre &__image {
    img {
      @apply mx-auto;
    }
  }

  &--right &__image {
    img {
      @apply ml-auto;
    }
  }

  p:last-child {
    @apply mb-0;
  }
}
