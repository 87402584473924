.about-item {
  &__inner {
    box-shadow: 12px 16px 17.3px -4px rgba(120, 133, 204, 0.1);

    @screen md {
      box-shadow: none;
    }
  }

  &:nth-child(1),
  &:nth-child(4),
  &:nth-child(5) {
    .about-item__inner {
      @screen md {
        box-shadow: 12px 16px 17.3px -4px rgba(120, 133, 204, 0.1);
      }
    }
  }
}
